import React, { useState } from 'react';
import postContactUs from "../../Api/postContactUs";


let ContactUs = () => {

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState('Send')

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setFormStatus('Submitting...');
    let conFom = {
      contactName: name,
      emailAddress: email,
      message: message,
    }
    console.log(conFom)

    postContactUs(conFom)
      .then(response => response.status)
      .then(() => {
        setFormStatus('Send');
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
  }
  return (
    <>
      <div className="contact-us-section  ">
        <h2 className="mb-3">Contact Us </h2>
        <h4 className="mb-3">Please refer to the <span className="important-txt">Frequently Asked Questions</span> page for tournament information. </h4>
        <h4 className="mb-3">A representative will respond to your inquiries within 48 hrs </h4>

        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Name
            </label>
            <input className="form-control" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input className="form-control" type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="message">
              Message
            </label>
            <textarea className="form-control" id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
          </div>
          <button className="btn btn-primary" disabled={loading} type="submit">
            {formStatus}
          </button>
        </form>
      </div>
    </>
  );
}

export default ContactUs;