import { Link, NavLink } from 'react-router-dom';
import myData from '../../../data/data.json';
import { Accordion, Card } from 'react-bootstrap';

let Faq = () => {


  // Assume you have loaded the FAQ data into faqData



  return (
    <>
      <div className="FaqContainer text-white ">
        <h3>Frequently Asked Questions</h3>
        <Link className='nav-link' to={`/registration`}>
        <h3><span className="important-txt">*Click here to register!*</span></h3>
        </Link>

        <div className="">

          {myData.categories.map((category, index) => (
            <>
              <h2>{category.name}</h2>
              <Accordion>
                {category.questions.map((question, qIndex) => (
                  <Accordion.Item eventKey={qIndex}>
                    <Accordion.Header >
                      {question.question}
                    </Accordion.Header>

                    <Accordion.Body >
                      <Card.Body className="FaqAnswers">{question.answer+"\n"} </Card.Body>
                      <NavLink className="center" to="/registration">Register Now</NavLink>
                    </Accordion.Body>
                  </Accordion.Item>

                ))}

              </Accordion>
            </>
          ))}


        </div>
      </div>
    </>
  );

}

export default Faq;
