
let Registration = () => {

    return (
        <>
            <div className="registration-container">
               
                <h4 className="mb-1 registerText text-center">
                    
                <h5 className="mb-1 registerText">  Zeffy provides <span className="important-txt">FREE</span> credit card processing to non-profit groups. Supporting the Zeffy platform is <span className="important-txt">OPTIONAL</span>. We <span className="important-txt">DO NOT</span> see if you contributed to Zeffy.</h5>
                <h5 className="mb-1 registerText" ><span className="important-txt">*Note*</span> If you <span className="important-txt">DO NOT</span> want to contribute to Zeffy:</h5>
                <h5 className="mb-1 registerText">1. In the Order Summary section, right of Support the 100% free platform we use! select <span className="important-txt">OTHER</span> in the dropdown box.</h5>
                <h5 className="mb-3 registerText">2. In the Contribution box, type the number <span className="important-txt">0 (zero).</span></h5>
                
                <h5 className="mb-1 registerText"><span className="important-txt bold">Please note: All donations are final and greatly appreciated.</span></h5> 

                </h4>

                <div className="zeffyThings1">
                    <iframe title='Donation form powered by Zeffy' className="zeffyThings2" src='https://www.zeffy.com/embed/ticketing/edcc98eb-3f43-4cbd-82d9-d37dced320e5' allowpaymentrequest allowTransparency="true">
                    </iframe>
                </div>

            </div>
        </>
    );

}

export default Registration;