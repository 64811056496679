const postContactUs = (parameters) => {
  // https://vizomenuapi.azurewebsites.net/api/arf?code=I5y2nhb4lehrjmZaV2R38Xag35wl1pHzIcOfDjCUKC7eAzFufKmfmw==
    const CONTACT_US_API_BASE = "https://vizomenuapi.azurewebsites.net/api/arf";
    const CODE_PARAM = "code";
    const CONTACT_US_API_KEY = "I5y2nhb4lehrjmZaV2R38Xag35wl1pHzIcOfDjCUKC7eAzFufKmfmw==";


    let url = new URL(`${CONTACT_US_API_BASE}`);
    url.searchParams.append(CODE_PARAM, CONTACT_US_API_KEY);

    return fetch(url, {
        
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          
            //make sure to serialize your JSON body
            body: JSON.stringify({ ...parameters  })
         
    });
}

export default postContactUs;