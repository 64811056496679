import React from 'react';
import { Link } from 'react-router-dom';

let Header = () => {
    return (
        <header  >
            <nav className={' navbar-expand header'}>
                <div className=''>
                    <div>
                        <ul className='navbar-nav justify-content-between w-100'>
                            <li>
                                <Link className='nav-link' to={`/`}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className='nav-link' to={`/registration`}>
                                    Register
                                </Link>
                            </li>
                            <li>
                                <Link className='nav-link' to={`/faq`}>
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link className='nav-link' to={`/contact-us`}>
                                    Contact Us
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
export default Header;