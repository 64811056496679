import posterImg from '../../../img/posterRichie2.jpg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

let HomePage = () => {

  return (
    <>
        <div className="homeContainer">
            <img className="home-page-image" alt="poker registration " src={posterImg}/>
            <div className="homePageButton">
                <Button className='register-btn'>
                    <Link className='nav-link' to={`/registration`}>
                        Register Now
                    </Link>
                </Button>
            </div>
        </div>
    </>
  );

}

export default HomePage;